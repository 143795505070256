#TranslationPanel {
  --translation-panel: 1 !important;
}

.translationPanelListItem {
  padding: 10px 5px;

}

#AIExplanation {
  font-size: 0.9em;
}

/* Optional: Add a gradient fade effect */
/* .card-body::after {
  content: '';
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  height: 1.5em;
  background: linear-gradient(to bottom, transparent, white);
  pointer-events: none;
} */

.explanation-card.show-ellipsis::after {
  content: 'more...';
  position: absolute;
  bottom: 0;
  right: 5px;
  font-size: 0.7rem;
  color: gray;
}

.grammar-guide-button {
  cursor: pointer;
}