.remove-item-button {
    cursor: pointer;
}

.remove-item-button:hover {
    color: red;
}

.play-item-button {
    cursor: pointer;
}

.small-cover-image {
  max-width: 150px; /* Adjust the size as needed */
  max-height: 150px;
  object-fit: cover; /* Ensures the image maintains aspect ratio */
}