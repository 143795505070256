#customTranslationTextArea {
  font-weight: bold;
}

#customTranslationTextArea::placeholder {
  font-weight: normal;
}

/* lowercase first letter - NOTE: this actually works only on content elements */
#customTranslationTextArea::first-letter {
  text-transform: lowercase !important;
}