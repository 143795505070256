.tabHeading {
  font-size: 1.2em;
}

.tabHeading .nav-link.active {
  font-weight: bold;
}

.tabHeading .nav-link {
  color: black;
}

@media screen and (max-width: 480px) {
  .tabHeading .nav-link {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
}

/* Add these styles to hide the scrollbar in WebKit browsers (Chrome, Safari) */
#library-tabs .nav-tabs {
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

#library-tabs .nav-tabs::-webkit-scrollbar {
  display: none;
}

/* Optional: Add some padding to prevent content from being cut off */
#library-tabs .nav-tabs .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

#library-tabs .nav-link {
  color: #212529;
  background-color: white;
}
#library-tabs .nav-link.active {
  background-color: #383838;
  color: white;
}

#library-tabs {
  border-bottom: 1px solid darkgray !important;
}

.tab-pane .card {
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  padding-top: 1.5em !important;
}
