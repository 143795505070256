.readerText {
  font-size: 1.2em;
  overflow-wrap: anywhere;
  background-clip: content-box;
  line-height: 1.9em;
  word-spacing: 2px; /* NOTE: on mobile this might cause the text to overflow to the right */
}

.sentence {
  position: relative;
  margin: 20px;
  padding-left: 25px; /* Adjust padding to accommodate the new clickable area */
  display: block; /* Ensure the sentence is a block element */
}

.sentence .clickable-area {
  position: absolute; /* Position the clickable area absolutely */
  left: 0; /* Align it to the left */
  top: 0; /* Align it to the top */
  bottom: 0; /* Stretch it to the bottom */
  width: 15px; /* Width of the clickable area */
  background-color: transparent; /* Default background */
  border-right: 5px solid #F5F5F5;
  cursor: pointer;
}

.sentence.highlighted-sentence .clickable-area {
  border-right: 5px solid gold; /* Highlight color */
}