/* deleteWordButton */
.deleteWordIcon {
  cursor: pointer;
}

.deleteWordIcon:hover {
  color: red;
}

.wordEditLink {
  cursor: pointer;
}

.wordEditLink:hover {
  color: blue;
}