.active-language-pair {
  font-weight: bold !important; /* Make text bold */
}

/* Solution 4: Handle legacy Bootstrap versions */
/* #no-caret-dropdown .caret {
  display: none !important;
} */

/* Solution 5: Additional browser compatibility */
#no-caret-dropdown .dropdown-toggle::-webkit-details-marker {
  display: none !important;
}

.dropup .dropdown-toggle::after {
  display: none !important;
}

/* Solution 6: Prevent any generated content */
#no-caret-dropdown .dropdown-toggle {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

#mobile-bottom-nav-bar .nav-link.active {
  text-decoration: underline;
  color: blue !important;
}
