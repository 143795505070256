.strength-button {
    border-top: 1px solid gray !important;
    /* width: 100%; */
}

.strength-button .btn {
    color: black;
    font-weight: bold;
    border: none;
}

.strength-button .btn:hover {
    border: 1px solid white;
    color: white;
}

.selected {
    border: 3px solid black !important;
    background-color: aquamarine !important;
    filter: brightness(200%) contrast(100%);
}

/* Review.css */
.strength-button .btn {
    width: 2rem;
    /* Adjust the width as needed */
    height: 2rem;
    /* Adjust the height as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Center content */
}