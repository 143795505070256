#tooltip {
    background-color: black;
    border-radius: 5px;
    padding: 0.6em 0.6em;
    color: white;
    /* cursor: pointer; */
    display: none;
    font-weight: bold;
}

#arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    /* Adjust this for arrow size */
    border-color: black transparent transparent transparent;
    position: absolute;
    margin: 0.3em 0.6em;
}

/* media query for mobile screens for tooltip */
@media (max-width: 768px) {
    #tooltip {
        font-size: 1.2em;
    }
}

.tooltip-action-button {
    cursor: pointer;
}