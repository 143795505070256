.remove-item-button {
  cursor: pointer;
}

.remove-item-button:hover {
  color: red;
}

.play-item-button {
  cursor: pointer;
}

/* In your CSS file */
.wrap-dropdown-text .dropdown-toggle {
  white-space: normal !important;
}