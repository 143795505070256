.remove-article-from-study-list-button {
  cursor: pointer;
}

.remove-article-from-study-list-button:hover {
  color: red;
}

.custom-dropdown-button {
  padding: 0 !important;
  margin: 0 !important;
  background-color: white !important;
  color: black !important;
  border: none !important;
}

.embla {
  position: relative;
  overflow: visible; /* Changed from hidden to visible */
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  gap: 1rem;
}

.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.embla__prev,
.embla__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s;
  z-index: 1;
}

.embla__prev {
  left: -60px; /* Position further left outside the content */
}

.embla__next {
  right: -60px; /* Position further right outside the content */
}

.embla__prev:disabled,
.embla__next:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.embla__prev:hover:not(:disabled),
.embla__next:hover:not(:disabled) {
  background: rgba(0, 0, 0, 0.7);
}

/* Media queries for mobile screens */
@media (max-width: 768px) {
  .embla__prev,
  .embla__next {
    width: 30px; /* Slightly smaller buttons */
    height: 30px;
    font-size: 0.5em;
  }

  .embla__prev {
    left: 0px; /* Move buttons closer to the content */
  }

  .embla__next {
    right: 0px;
  }
}

/* Media queries for iPad screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .embla__prev {
    left: -3em; /* Move buttons further from the edges */
  }

  .embla__next {
    right: -3em;
  }
}

.embla__prev::before,
.embla__next::before {
  display: inline-block; /* Ensure the icon is displayed */
  width: auto; /* Allow the icon to size naturally */
  height: auto; /* Allow the icon to size naturally */
  text-align: center; /* Center the icon */
}

.embla__prev::before {
  font-family: "Bootstrap Icons"; /* Ensure the correct font family is used */
  font-size: 20px; /* Adjust size as needed */
  color: white; /* Ensure the icon is visible */
}

.embla__next::before {
  font-family: "Bootstrap Icons"; /* Ensure the correct font family is used */
  font-size: 20px; /* Adjust size as needed */
  color: white; /* Ensure the icon is visible */
}
