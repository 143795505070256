body {

}

.linkchimp {
    padding-top: 30px;
}

/* NOTE: avoid the content going under the mobile bottom menu */

.padding-to-avoid-overlap-with-mobile-nav {
  padding-bottom: 6em; /* Add 6em bottom margin for mobile */
}

@media (min-width: 768px) {
  .padding-to-avoid-overlap-with-mobile-nav {
    padding-bottom: 1em; /* Add 1em bottom margin for desktop */
  }
}