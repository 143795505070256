.onboarding-carousel .carousel-control-prev,
.onboarding-carousel .carousel-control-next {
  width: 6%;
}
.onboarding-carousel .carousel-control-prev {
  margin-left: -5px !important;
}
.onboarding-carousel .carousel-control-next {
  margin-right: -5px !important;
}
.onboarding-carousel .carousel-control-prev,
.onboarding-carousel .carousel-control-next {
  position: absolute;
  align-items: flex-end;
  display: flex;
  bottom: 10px !important;
}
.onboarding-carousel .carousel-indicators {
  margin-bottom: 0px !important;
}
#onboardingButton {
  cursor: pointer !important;
  font-size: 1rem !important;
}

.video-responsive {
    position: relative;
    width: 90%; /* Adjust the width as necessary */
    margin: auto; /* Center the video container */
}

.video-responsive::before {
    content: "";
    display: block;
    padding-top: 56.25%; /* Default for 16:9 aspect ratio */
}

.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

