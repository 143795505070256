.readerText {
  font-size: 1.2em;
  overflow-wrap: anywhere;
  line-height: 1.9em;
  word-spacing: 2px;
}

.message {
  position: relative;
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 1rem;
  max-width: 90%;
  line-height: 1.4em;
  word-spacing: 1px;
  border: 1px solid #e9ecef;
  display: block;
}

.user-message {
  align-self: flex-end;
  margin-left: auto;
  flex-direction: row-reverse;
  background-color: #f8f9fa;
}

.ai-message {
  align-self: flex-start;
  margin-right: auto;
  background-color: white;
  word-wrap: break-word;
  white-space: normal;
}

.message .word {
  font-size: 1.2em;
  overflow-wrap: anywhere;
  background-clip: content-box;
  line-height: 1.9em;
  word-spacing: 2px;
}

.message .word.known {
  /* background-color: white; */
  border-bottom: 2px dotted rgb(255, 165, 0, 1);
  cursor: pointer;
  opacity: 0.8;
}

/* .message .word.skipped {
  background-color: transparent !important;
} */

.message .word.unknown {
  background-color: lightblue;
  cursor: pointer;
  border-bottom: 2px dotted transparent;
}

.message .highlighted {
  background-color: yellow !important;
  cursor: pointer;
}

.message-correction {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  /* font-size: 0.8em; */
  color: green;
}

.sticky-bottom-row {
  position: sticky;
  bottom: 0;
  z-index: 1030;
}

#textContentFlexColumn {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (max-width: 768px) {
  #textContentFlexColumn {
    padding-left: 0; /* Remove padding for smaller screens */
    padding-right: 0; /* Remove padding for smaller screens */
  }
}
