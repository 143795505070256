.flashcard {
  position: absolute;
  left: 50%;
  /* transform: translateX(-50%); */
  /* Ensure you have a defined width or max-width */
  width: 100%; /* adjust as needed */
  height: 90%;
  padding: 5px;
  min-height: 400px; /* Minimum height of the flashcard */
  min-width: 300px; /* Minimum width of the flashcard */
  max-height: 600px; /* Maximum height of the flashcard */
  max-width: 450px; /* Maximum width of the flashcard */
  margin: auto; /* Centering the flashcard in the column */
  border-radius: 10px;
  background-color: #28282b;
  color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.scene {
  perspective: 1500px;
  perspective-origin: center;
}

.flashcard-word {
  white-space: normal !important;
  overflow-wrap: break-word !important;
  font-size: 22px;
  font-weight: bold;
}

.flashcard-translation {
  margin-top: 15px;
  font-size: 22px;
  color: greenyellow;
  min-height: 50px;
  /* font-weight: bold; */
}

.flashcard-strength-buttons {
  border-top: 1px solid gray !important;
  width: 100%;
}

.flashcard-strength-buttons .btn {
  color: black;
  font-weight: bold;
  border: none;
}

.flashcard-strength-buttons .btn:hover {
  border: 1px solid white;
  color: white;
}

.flashcard-strength-buttons .selected {
  border: 3px solid white;
  filter: brightness(200%) contrast(100%);
}

/* Review.css */
.flashcard-strength-buttons .btn {
  width: 2.5rem; /* Adjust the width as needed */
  height: 2.5rem; /* Adjust the height as needed */
  display: flex;
  justify-content: center;
  align-items: center; /* Center content */
}

#strengthButtonsContainer {
  width: 100%;
}

#strengthTitle {
  font-weight: bold;
}

#flashcardContainer {
  position: relative;
  height: 400px;
  width: 300px;
  cursor: pointer;
  transition: transform 0.3s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  transform-origin: center;
}

.isFlipped {
  transform: rotateY(180deg);
}

.back {
  transform: rotateY(180deg);
  z-index: 2;
}

.front {
  z-index: 1;
}

.front,
.back {
  /* position: absolute;
  transform-origin: center center; */

  /* backface-visibility: hidden; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
