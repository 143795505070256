/* prevent mobile browsers from having bouncing effects and page refresh when pulling down */
html,
body {
  overscroll-behavior-y: none;
}

.known {
  /* background-color: white; */
  border-bottom: 2px dotted rgb(255, 165, 0, 1);
  cursor: pointer;
}

.known-strength-0 {
  border-bottom: 2px dotted rgb(255, 165, 0, 1) !important;
}
.known-strength-1 {
  border-bottom: 2px dotted rgb(255, 165, 0, 0.8) !important;
}
.known-strength-2 {
  border-bottom: 2px dotted rgb(255, 165, 0, 0.65) !important;
}
.known-strength-3 {
  border-bottom: 2px dotted rgb(255, 165, 0, 0.5) !important;
}
.known-strength-4 {
  border-bottom: 2px dotted rgb(255, 165, 0, 0.3) !important;
}
.known-strength-5 {
  border-bottom: none !important;
}

.skipped {
  /* background-color: white; */
  cursor: pointer;
  border-bottom: 2px dotted transparent;
}

.punctuation {
  /* background-color: white; */
  cursor: auto;
  /* border-top: 2px dotted transparent; */
  border-bottom: 2px dotted transparent;
}

.number {
  /* background-color: white; */
  cursor: pointer;
  border-bottom: 2px dotted transparent;
}

.unknown {
  background-color: lightblue;
  cursor: pointer;
  border-bottom: 2px dotted transparent;
}

.highlighted {
  background-color: yellow;
  cursor: pointer;
  display: inline; /* or inline-block if needed */
}

/* explosion effect */
.fragment {
  position: fixed; /* Prevent layout shifts */
  width: 5px;
  height: 5px;
  background-color: yellow;
  animation: explode-piece 1.5s ease-in-out forwards;
  pointer-events: none; /* Prevent interaction */
}

@keyframes explode-piece {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(calc(var(--x) * 1px), calc(var(--y) * 1px)) scale(0.5);
    opacity: 0;
  }
}

.unknown:hover,
.known:hover,
.skipped:hover {
  background-color: #fafad2;
}

.highlighted:hover {
  background-color: yellow;
}

.title {
  font-size: 1.3em;
  font-weight: bold;
  color: #36454f;
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white; /* Or whatever background color you prefer */
}

.word {
  font-size: 1.2em;
  overflow-wrap: anywhere;
  background-clip: content-box;
  line-height: 1.9em;
  word-spacing: 2px; /* NOTE: on mobile this might cause the text to overflow to the right */
}

/* thai words need extra spacing otherwise they're not distinguishable */
.thai {
  margin-right: 2px;
  /* background-color: black !important; */
}

/* NOTE: this might cause problems theoretically - needs more testing */
#translations {
  margin-top: 3svh;
  max-height: 94svh; /* Adjust the maximum height as needed */
  overflow-y: auto; /* Enable vertical scrolling when content overflows */
  --translation-panel: 1 !important; /* This is a custom CSS variable that propagates down the DOM tree for checking if the panel was clicked*/
  background-color: white;
  z-index: 900;
  border: 2px solid lightgray;
  padding: 0px 20px 20px 20px;
  border-radius: 8px;
}

/* Reader.css */
@media screen and (max-width: 768px) {
  /* You can set the max-width according to your needs */
  #translations {
    display: none;
    z-index: 100;
    /* this was originally 100svh but using 100% to account for mobile in the Music reader */
    max-height: 100% !important;
    /* this is for the Music reader only essentially */
    height: 100% !important;
    border-radius: 0 !important;
    margin: 0 !important;
    border: 0 !important;
  }
}

/* NOTE: the rounded corners are broken, I should wrap this inside another element to fix it */
#translations::-webkit-scrollbar {
  width: 5px;
}

#translations::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#translations::-webkit-scrollbar-thumb {
  background: #888;
}

#translations::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#closeTranslationsPanelButton {
  display: block;
  position: relative;
  top: 0svh;
  right: -90%;
}

.closeTranslationsPanelButtonBigger {
  font-size: 2em;
  right: -80%;
}

@media screen and (max-width: 768px) {
  /* You can set the max-width according to your needs */
  #closeTranslationsPanelButton {
    display: block;
  }
}

#readerBugButton {
  color: rgba(127, 127, 127, 1.5);
  background-color: white;
  padding-left: 0rem;
  padding-right: 0rem;
  margin-right: 1svh;
  cursor: pointer;
}

#readerCloseReaderButton {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

#selectedWordTranslationHeading {
  font-weight: bold;
  color: #36454f;
  font-size: 1.1em;
}

#selectedWordOriginal {
  font-weight: bold;
  font-size: 1em;
}

#selectedWordTranslation {
  font-weight: bold;
  font-size: 1em;
}

#selectedWordSpeakerButton {
  margin-right: 5px;
}

.debuggingInfo {
  font-size: 0.8em;
  color: #36454f;
}

body {
  background-color: white;
}

.arrowButton {
  padding: 0.2em 0.1em !important;
}

#textContent {
  padding: 40px 20px 10% 20px;
}

/* make mediaquery for textContent */
@media screen and (max-width: 768px) {
  /* You can set the max-width according to your needs */
  #textContent {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 20px !important;
  }
}

.nextButtonTooltip {
  background-color: #e8e8e8;
  border-radius: 10px;
  border: 2px solid lightgray;
  padding: 10px;
  /* margin can't be put here - Popper will throw a warning, it should be in the popperConfig property instead */
  /* margin: 10px; */
  max-width: 250px;
  text-wrap: balance;
  line-height: 1.7 !important;
}

.custom-dropdown-toggle::after {
  /* display: none; */
  color: gray;
}

.no-caret::after {
  display: none;
}


.streak-number {
  animation: scale 0.5s ease-in-out;
  animation-delay: 1000ms;
}

@keyframes scale {
  0% {
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}