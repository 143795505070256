@media (min-width: 768px) {
  #translations {
    position: sticky;
    top: 1rem;
    height: 100vh;
    overflow-y: auto;
    /* display: none; */
    /* initially hidden */
    z-index: 1000;
  }

  #translations.full-screen {
    width: 100%;
    height: 100%;
  }
}

.sticky-top-row {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  padding-bottom: 1rem;
  /* Optional: to avoid transparency issues */
}

#tooltip {
  z-index: 1000;
}

.youtube-container {
  width: 100%;
  max-width: 55%;
  /* Adjust this value to your preference for desktop */
  margin: 1em auto;
  position: relative;
}

.youtube-aspect-ratio {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

.youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .youtube-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  .youtube-container {
    max-width: 45%;
    /* Adjust this value for mobile landscape mode */
  }
}

.no-caret::after {
  display: none;
}
